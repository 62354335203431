import { GhmCategoryType } from "../../../constants/layersCategories";
import { MapServerClient } from "utils/ogcClients";
import { GetMapParametersI, LayerConfigI, LayerDefinitionI } from "../../../types";
import {
  getLayersConfig,
  handleLayersName,
  makeCommonConfig,
  MakeConfigParamsI
} from "../../../utils/layerConfigUtils";

const mapFileName = "RMAP";
const mapServerClient = new MapServerClient(mapFileName);

const layersDefinitions: LayerDefinitionI[] = [
  {
    type: "layer",
    name: "cover",
    layerName: "cover",
    title: "Forest Cover, version 1",
    description: ""
  },
  {
    type: "layer",
    name: "cover_v2",
    layerName: "cover_v2",
    title: "Forest Cover, version 2",
    defaultSelected: true,
    description: ""
  },
  {
    type: "layer",
    name: "types",
    layerName: "types",
    title: "Forest Types, preliminary version",
    description: ""
  }
];

const makeConfig = (props: MakeConfigParamsI): LayerConfigI => {
  const { definition, year } = props;
  const { name } = definition;

  const params: GetMapParametersI = {
    layers: handleLayersName(definition, year),
    ...mapServerClient.WMSParams
  };

  return {
    ...makeCommonConfig(props, mapServerClient),
    params,
    ghmCategory: "harmonized" as GhmCategoryType
  };
};

export const layersConfig = getLayersConfig(layersDefinitions, makeConfig);
