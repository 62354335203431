import { Box, Typography } from "@mui/material";
import SummaryPage, { SummaryItem } from "components/reusables/SummaryPage";

import colorByPageName from "constants/colorByPageName";
import StyledLink from "components/reusables/StyledLink";

import { removeOpacity } from "components/reusables/removeColorOpacity";

const title = "Global Forest Mapping and Monitoring";
// const color = removeOpacity.color(colorByPageName[title]);
const color = colorByPageName[title];
const minWidth = 500;

const GfaDescription = () => {
  return (
    <Typography>
      This section presents the global forest attribute layers developed by JRC. The analysis scheme and data products
      were designed specifically for the Web platform of the EU observatory on deforestation and forest degradation.The
      spatial forest coverage is derived from the Copernicus Global Land Cover dataset for the assessment year 2019
      (accessed in February 2022). The original Copernicus data segments were processed into a continuous global forest
      mask which is compliant with the FAO forest definition. This forest mask has then be used to produce various
      forest attribute layers summarising the spatial status of forest cover and its degree of forest degradation. These
      layers are visualized using the original WGS84 map projection for explanatory and user-friendly visualisation.
      Statistical estimates of the forest attribute layers are derived using an equal area projection for a total of 278
      reporting units, comprised of 255 GISCO countries, 21 global ecological zones, and the full global coverage
    </Typography>
  );
};

const GhmDescription = () => {
  return (
    <Typography>
      The map of forest cover changes shows the following drivers of forest cover changes at the global scale and on an
      annual basis between 2016 to 2023: (i) deforestation for commodity production, (ii) forest harvest by intensity
      level in areas dedicated to large-scale forestry operations (also referred to as harvested areas), (iii)
      disturbances which include (a) forest cover loss due to fires inside harvested areas, (b) forest cover loss due to
      fires outside harvested areas, (c) forest cover loss due to extreme events, e.g., wind throw, insect outbreaks,
      inside harvested areas and (d) small-scale disturbances in the tropical moist forests due to selective logging,
      understory fire or natural disturbances such as wind breaks or drought.
    </Typography>
  );
};

const TmfDescription = () => {
  return (
    <Typography>
      The Tropical Moist Forest dataset from the Joint Research Centre provides information on changes in forest cover
      from 1990 up to the year 2023 in the humid tropics. The wall-to-wall maps at 0.09 ha resolution (30m) depict the
      tropical moist forest extent and the related disturbances (deforestation and degradation), and post-deforestation
      recovery (or forest regrowth). Deforestation refers to a change in land cover (from forest to non-forested land)
      when degradation refers to a short-duration disturbance in a forest remaining forested such as selective logging,
      fires and unusual weather events (hurricanes, droughts, blowdown).
    </Typography>
  );
};

//const EufcDescription = () => {
//  return (
//    <Typography>
//      This section presents the european forest attribute layers developed by JRC. (to be completed)
//    </Typography>
//  );
//};

const RmapDescription = () => {
  return (
    <Typography>
      Global forest maps are produced from a combination of existing global spatial layers, e.g. on land cover, land use
      and tree height. The map on global forest cover (GFC 2020) aims at representing the state of forest cover by 31
      December 2020. The global land cover from the{" "}
      <StyledLink
        href="https://esa-worldcover.org/en"
        target="_blank"
        color={color}
        aria-label="Link to ESA Worldwide land cover mapping (external link will be opened in a new window)"
      >
        ESA World Cover
      </StyledLink>{" "}
      project serves as one baseline layer to define the extent of tree cover for year 2020 at 10 m spatial resolution.
      In 2024, GFC 2020 was improved by integrating user feedback, and new or revised spatial data layers. For direct
      access and metadata, please consult the JRC data catalogue (
      <StyledLink
        href="https://data.jrc.ec.europa.eu/dataset/e554d6fb-6340-45d5-9309-332337e5bc26"
        target="_blank"
        color={color}
        aria-label="Link to the JRC catalog on the dataset"
      >
        JRC, 2024
      </StyledLink>
      {""}
      ). A technical report (
      <StyledLink
        href="https://data.europa.eu/doi/10.2760/262532"
        target="_blank"
        color={color}
        aria-label="Technical report released in March 2024 regarding GFC2020"
      >
        Bourgoin et al 2024
      </StyledLink>
      {""}) describes the mapping approach for the first version; a technical report for version 2 is forthcoming. A
      comparative study between GFC 2020 version 1 and a national map was carried out over Ivory Coast ({""}
      <StyledLink
        href="https://data.europa.eu/doi/10.2760/7042220"
        target="_blank"
        color={color}
        aria-label="National case comparison for Ivory Coast"
      >
        Verhegghen et al, 2024
      </StyledLink>
      {""}).The GFC 2020 map may be revised if new information, additional large-scale data layers or revised global
      spatial data layers will be made available for year 2020.
      <br />
      The preliminary version of the global map of forest types for year 2020 (GFT 2020) shows a spatial representation
      of forest categories in line with the definition of forest degradation as set out in the EUDR. A consolidated
      version of GFT 2020 map will be released in 2025. The viewer shows both versions of GFC 2020 and a preliminary
      version of the global map of forest types for year 2020.
      <br />
      Global maps of forest cover and forest types for year 2020 (GFC 2020 and GFT 2020) have no legal value per se but
      may serve as a tool to comply with the Regulation on deforestation-free supply chains. More specifically, it may
      help to assess the risk of deforestation (GFC 2020) or the risk of forest degradation (GFT 2020), without
      dispensing operators and Member States from the duty to carry out due diligence.
    </Typography>
  );
};

const MainContent = () => {
  return (
    <Box>
      <SummaryItem
        title="Global Forest maps for year 2020 in support to EUDR"
        content={<RmapDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="rmap"
        ariaLabel="Global Forest maps for year 2020 in support to EUDR"
      />
      <SummaryItem
        title="Global Forest Attributes"
        content={<GfaDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="gfa"
        ariaLabel="Global forest attributes"
      />
      <SummaryItem
        title="Global Map of Forest Cover Changes and their Drivers"
        content={<GhmDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="ghm"
        ariaLabel="Global Map of Forest Cover Changes"
      />
      <SummaryItem
        title="Tropical Moist Forest"
        content={<TmfDescription />}
        color={color}
        mainColor={color}
        minWidth={minWidth}
        linkTo="tmf"
        ariaLabel="Tropical Moist Forest"
      />
    </Box>
  );
};

/*

      <SummaryItem
        title="European Forest Attributes"
        content={<EufcDescription />}
        color={color}
        mainColor={color}
        linkTo="eufc"
      />

 */
const GlobalForestMonitoringSummaryPage = () => {
  return <SummaryPage backgroundImgPath="background_gfm" title={title} color={color} mainContent={<MainContent />} />;
};

export default GlobalForestMonitoringSummaryPage;
